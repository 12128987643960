import React, { useEffect, StrictMode } from 'react';
import './layout.less';
import '../styles/typography.less';
import '../styles/icons/style.less';
import '../styles/animations.less';
import 'components/block-elements/ui-btn/ui-btn.less';
import 'components/block-elements/ui-select/ui-select.less';
import 'components/block-elements/standings-table-colors/standings-table-colors.less';
import 'components/block-elements/tournament-logo/tournament-logo.less';

const Layout = ( props ) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div id='Layout' className={props.className}>
      <StrictMode>
        {props.children}
      </StrictMode>
      </div>
    );
}

export default Layout;
