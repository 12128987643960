import React, { useState, useContext } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import styles from './main-navigation.module.less';
import NavigationDrawer from '../navigation-drawer/navigation-drawer';
import { AuthContext } from 'components/helpers/auth-context';

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const logo = get(props, 'data.logotype.file.url');
  let navigationItemList = get(props, 'data.navigationItemsList');

  const linkToLandingPage = get(props, 'data.linkToLandingPage.slug');
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  // const toggleMenuButton = toggleMenuButton.bind(this);
  // const handleToggle = handleToggle.bind(this);
  // const handleLinkClick = handleLinkClick.bind(this);

  function handleToggle() {
    return !navigationDrawerOpen
      ? document.body.classList.remove('is-expanded')
      : document.body.classList.add('is-expanded');
  }

  function toggleMenuButton() {
    setNavigationDrawerOpen(
      (prevState) => ({ navigationDrawerOpen: !prevState.navigationDrawerOpen }),
      () => {
        handleToggle();
      }
    );
  }

  function handleLinkClick() {
    setNavigationDrawerOpen({ navigationDrawerOpen: false }, () => {
      handleToggle();
    });
  }

  let navigationDrawer;

  if (navigationDrawerOpen) {
    navigationDrawer = (
      <NavigationDrawer
        showDrawer={navigationDrawerOpen}
        navigationItemList={navigationItemList}
        onItemClick={() => {
          handleLinkClick();
        }}
      />
    );
  }
  return (
    <nav>
      {logo && navigationItemList && (
        <div className={styles.mainNavigation}>
          <div className={styles.topSectionOuterWrapper}>
            {linkToLandingPage && (
              <div className={styles.mainNavigation__imageWrapper}>
                <Link to={linkToLandingPage} onClick={handleLinkClick}>
                  <img className={styles.mainNavigation__logo} src={logo} alt='logo' />
                </Link>
              </div>
            )}
            <button
              className={[styles.mainNavigation__menuButton, 'waves-effect'].join(' ')}
              onClick={toggleMenuButton}
              aria-label='Menu'>
              <i
                className={[navigationDrawerOpen ? 'icon-ic-close' : 'icon-ic-menu', styles.mainNavigation__icon].join(
                  ' '
                )}
              />
            </button>
            <ul className={styles.navList}>
              {navigationItemList &&
                navigationItemList.map((listItem) => (
                  <li key={listItem.id + 'deskTopNav'} className={styles.listItem}>
                    <Link to={listItem.url.slug} className={styles.listItemLink}>
                      {listItem.title.toUpperCase()}
                    </Link>
                  </li>
                ))}
                {auth.isAuthorize && auth.user.userRoles.find(el => el == 'Admin') && 
                  <li key={'admin-panel' + 'deskTopNav'} className={styles.listItem}>
                  <Link to={"/admin-panel"} className={styles.listItemLink}>
                    {"ADMIN PANEL"}
                  </Link>
                </li>}
            </ul>
          </div>
          <div className={styles.mainNavigationSpacer}></div>
        </div>
      )}
      {navigationDrawer}
    </nav>
  );
};

// export const fragmentMainNavigation = graphql`
//   fragment MainNavigation on ContentfulMainNavigation {
//     id
//     logotype {
//       file {
//         url
//       }
//     }
//     navigationItemsList {
//       id
//       title
//       url {
//         slug
//       }
//     }
//     linkToLandingPage {
//       slug
//     }
//   }
// `;

export const fragmentMainNavigation = graphql`
  fragment MainNavigation on ContentfulMainNavigation {
    id
    logotype {
      file {
        url
      }
    }
    navigationItemsList {
      id
      title
      url {
        slug
      }
    }
    linkToLandingPage {
      slug
    }
  }
`;

export default MainNavigation;
