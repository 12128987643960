import React, { Component, Fragment }from 'react';
import PropTypes from 'prop-types';

var Link;

export default class UiBtn extends Component {

    static propTypes = {
        onClick: PropTypes.func,
        element: PropTypes.oneOf(['button', 'url']),
        label : PropTypes.string,
        url: PropTypes.string,
        isTruncating: PropTypes.bool,
        isDisabled: PropTypes.bool,
        className: PropTypes.string,
        tabIndex: PropTypes.number,
        routerLink: PropTypes.any,
        target: PropTypes.oneOf(['_self', '_blank']),
        isTwiceLine: PropTypes.bool
    }

    static defaultProps = {
        element : 'button',
        label: '',
        url:'', //Requires element prop to be set to url
        isTruncating: false, //If true, button truncates text such as "Click button to..."
        isDisabled: false,
        className: '',
        tabIndex: 0,
        routerLink : null
    }

    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
        this.state = { isActive: false };
        Link = this.props.routerLink ? this.props.routerLink : null;

        this.ref_button = React.createRef();
    }

    render() {
        return this.getBtnByElement();
    }

    getBtnByElement(){
        return !this.props.isDisabled &&
               this.props.element === 'url' &&
               this.props.url ? this.getHtmlBtnAsUrl() : this.getHtmlBtn();
    }

    getHtmlBtn(){
        let cssButton = this.getCssButton();
        let htmlBtn = this.getBtnHTML();

        return (<button ref={this.ref_button}
        style={this.props.style ? this.props.style : {}}
        className={[cssButton, this.props.theme].join(" ")}
        onClick={this.onClick}
        disabled={this.props.isDisabled}
        tabIndex={this.props.tabIndex}>{htmlBtn}{this.props.children}</button>);
    }

    getHtmlBtnAsUrl(){

        let cssButton = this.getCssButton();
        let htmlBtn = this.getBtnHTML()
        let isExternalUrl = /^((http|https):\/\/)/.test(this.props.url);
        let urlTarget = isExternalUrl && this.props.target ? this.props.target : undefined;

        return isExternalUrl || !Link ?
        (<a href={this.props.url} target={urlTarget} ref={this.ref_button} className={[cssButton,"ui-btn-element-url"].join(" ")} onClick={this.onClick}><span className={"ui-btn__url-inner-wrapper"}>{htmlBtn}</span></a>) :
        (<Link to={this.props.url} innerRef={ref => this.ref_button.current = ref} className={cssButton} onClick={this.onClick}><span className={"ui-btn__url-inner-wrapper"}>{htmlBtn}</span></Link>);
    }

    getBtnHTML(){

        return (
            <Fragment>
            {
                this.props.iconLeft && this.props.iconLeft.icon &&
                <span style={{
                    'textAlign': this.props.iconLeft.align ? this.props.iconLeft.align : 'left',
                    'width': this.props.iconLeft.width ? this.props.iconLeft.width : ''
                    }}
                    className={['ui-btn__icon-left', this.props.iconLeft.icon, this.getIconLeftState()].join(" ")}></span>
            }
            {
                this.props.iconLeft && this.props.iconLeft.iconToggle &&
                <span style={{
                    'textAlign': this.props.iconLeft.align ? this.props.iconLeft.align : 'left',
                    'width': this.props.iconLeft.width ? this.props.iconLeft.width : ''
                    }}
                    className={['ui-btn__icon-left', this.props.iconLeft.iconToggle ? this.props.iconLeft.iconToggle : '', this.getIconLeftToggleState()].join(" ")}></span>
            }
            {
                this.props.label &&
                <span className={this.props.isTwiceLine ? 'ui-btn__label_twiceLine' : 'ui-btn__label'}>{this.props.label}</span>
            }
            {
                this.props.iconRight && this.props.iconRight.icon &&
                <span style={{
                        'textAlign': this.props.iconRight.align ? this.props.iconRight.align : 'right',
                        'width': this.props.iconRight.width ? this.props.iconRight.width : ''
                      }}
                      className={['ui-btn__icon-right', this.props.iconRight.icon, this.getIconRightState()].join(" ")}></span>
            }
            {
                this.props.iconRight && this.props.iconRight.iconToggle &&
                <span style={{
                        'textAlign': this.props.iconRight.align ? this.props.iconRight.align : 'right',
                        'width': this.props.iconRight.width ? this.props.iconRight.width : ''
                      }}
                      className={['ui-btn__icon-right', this.props.iconRight.iconToggle, this.getIconRightToggleState()].join(" ")}></span>
            }
            </Fragment>
        );
    }

    onClick(event){
        if (this.props.isDisabled){
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            this.setState({ isActive : !this.state.isActive});

            if(this.props.onClick) {
                this.props.onClick(event);
            }
        }

        return true;
    }

    getCssButton(){
        return (`ui-btn ${this.getIsTruncating()} ${this.getClassName()}`).trim();
    }

    getIsTruncating() {
        return this.props.isTruncating ? "ui-btn--truncate" : "";
    }

    getIconLeftState(){
        return this.state.isActive && this.props.iconLeft && this.props.iconLeft.iconToggle ? "" : "ui-btn__icon-left--active";
    }

    getIconLeftToggleState(){
        return this.state.isActive && this.props.iconLeft && this.props.iconLeft.iconToggle ? "ui-btn__icon-left--active" : "" ;
    }

    getIconRightState(){
        return this.state.isActive && this.props.iconRight.iconToggle ? "" : "ui-btn__icon-right--active";
    }

    getIconRightToggleState(){
        return this.state.isActive && this.props.iconRight.iconToggle ? "ui-btn__icon-right--active" : "" ;
    }

    getClassName(){
        return this.props.className ? this.props.className : "";
    }
}
