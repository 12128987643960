import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import styles from './footer-navigation.module.less';
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';

class FooterNavigation extends Component {
  constructor(props) {
    super(props);

    this.navigationItemsList = get(props, 'data.navigationItemsList');

    this.sectionOneTitle = get(props, 'data.footerSection1Title');
    this.textOne = get(props, 'data.textBlock.textBlock');
    this.sectionOneTitleTwo = get(props, 'data.sectionOneTitleTwo');
    this.textTwo = get(props, 'data.textBlockTwo.textBlockTwo');
    this.facebook = get(props, 'data.linkToFacebook');
    this.instagram = get(props, 'data.linkToInstagram');
    this.twitter = get(props, 'data.linkToTwitter');
    this.facebookIcon = get(props, 'data.facebookIcon.file.url');
    this.instagramIcon = get(props, 'data.instagramIcon.file.url');
    this.twitterIcon = get(props, 'data.twitterIcon.file.url');
    this.sectionTwoTitle = get(props, 'data.footerSection2Title');
    this.richText = get(props, 'data.responsibleBetting.responsibleBetting');
    this.sectionThreeTitle = get(props, 'data.footerSection3Title');
    this.navigationItemsList = get(props, 'data.navigationItemsList');
    this.logo = get(props, 'data.logotype.file.url');
    this.partnerLogo = get(props, 'data.partnerLogo.file.url');
    this.linkToLandingPage = get(props, 'data.linkToLandingPage.slug');
    this.linkToDataPolicy = get(props, 'data.linkToDataPolicy.slug');
    this.linkToEmail = get(props, 'data.linkToEmail');
    this.emailIcon = get(props, 'data.emailIcon.file.url');
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <div className={styles.footerTop}>
            <div className={styles.sectionOne}>
              <div className={styles.sectionOneTitle}>{this.sectionOneTitle}</div>
              <div className={`${styles.richTextBlock} ${styles.paddingBottom}`}>
                {documentToReactComponents(JSON.parse(this.textOne))}
              </div>
              <div className={styles.sectionOneTitle}>{this.sectionOneTitleTwo}</div>
              <div className={styles.richTextBlock}>{documentToReactComponents(JSON.parse(this.textTwo))}</div>
              <div className={styles.socialIcons}>
                <a href={this.facebook} target='_blank' rel='noopener noreferrer'>
                  <img src={this.facebookIcon} alt='Facebook Icon' />
                </a>
                <a href={this.instagram} target='_blank' rel='noopener noreferrer'>
                  <img src={this.instagramIcon} alt='Instagram Icon' />
                </a>
                <a href={this.twitter} target='_blank' rel='noopener noreferrer'>
                  <img src={this.twitterIcon} alt='Twitter Icon' />
                </a>
                <a href={this.linkToEmail} target='_blank' rel='noopener noreferrer'>
                  <img src={this.emailIcon} alt='Email Icon' />
                </a>
              </div>
            </div>
            <div className={styles.sectionTwo}>
              <div className={styles.sectionTwoTitle}>{this.sectionTwoTitle}</div>
              <div className={styles.richTextBlock}>{documentToReactComponents(JSON.parse(this.richText))}</div>
            </div>
            <div className={styles.sectionThree}>
              <div className={styles.sectionThreeTitle}>{this.sectionThreeTitle}</div>
              {this.navigationItemsList && (
                <ul className={styles.navList}>
                  {this.navigationItemsList &&
                    this.navigationItemsList.map(listItem => {
                      return (
                        <li key={listItem.id + 'footerNav'} className={styles.listItemRight}>
                          <LinkWrapper link={listItem.url && listItem.url.slug} className={styles.listItemLink}>
                            {listItem.title}
                          </LinkWrapper>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        </div>
        {this.logo && this.linkToLandingPage && (
          <div className={styles.footerBottom}>
            <div>
              <LinkWrapper link={this.linkToLandingPage}>
                <img className={styles.logo} src={this.logo} alt='logo' />
              </LinkWrapper>
            </div>
            <div className={styles.copy}>
              <LinkWrapper link={this.linkToDataPolicy} className={styles.linkToDataPolicy}>
                Data policy
              </LinkWrapper>
              &nbsp;|&nbsp;
              <span className={styles.copyText}>{this.getYear()} &copy;&nbsp;Copyright Matchcentermedia AB</span>
            </div>
          </div>
        )}
        {this.partnerLogo && (
            <div className={styles.footerBottom}>
            <div className={styles.partnerLogoWrapper}>
              <img className={styles.partnerLogo} src={this.partnerLogo} alt='data by sportsradar' />
            </div>
          </div>
          )}
      </footer>
    );
  }
}

export default FooterNavigation;

export const fragmentFooterNavigation = graphql`
  fragment FooterNavigation on ContentfulFooter {
    id
    footerSection1Title
    textBlock {
      id
      textBlock
    }
    sectionOneTitleTwo
    textBlockTwo {
      id
      textBlockTwo
    }
    facebookIcon {
      file {
        url
      }
    }
    linkToFacebook
    instagramIcon {
      file {
        url
      }
    }
    linkToInstagram
    twitterIcon {
      file {
        url
      }
    }
    linkToTwitter
    emailIcon {
      file {
        url
      }
    }
    linkToEmail
    footerSection2Title
    responsibleBetting {
      id
      responsibleBetting
    }
    footerSection3Title
    navigationItemsList {
      id
      title
      url {
        slug
      }
      footerDescriptionText {
        childMarkdownRemark {
          html
        }
      }
    }
    logotype {
      file {
        url
      }
    }
    partnerLogo {
      file {
        url
      }
    }
    linkToLandingPage {
      slug
    }
    linkToDataPolicy {
      slug
    }
  }
`;
